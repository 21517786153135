<template>
  <div
    class="modal fade"
    id="modalAccountEstimated"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="card-modal">
          <div class="modal-header">
            <h5 class="modal-title">Rincian Pembelian</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body mt-3">
            <div class="form-group d-flex align-items-center">
              <label class="form-label">Akun Pembelian </label>
              <div class="text">{{ formPembelian.nama_akun }}</div>
            </div>
            <div class="form-group d-flex align-items-center">
              <label class="form-label">Tanggal </label>
              <div class="text">
                {{
                  formDetail.tanggal_beli
                    ? convertDate(formDetail.tanggal_beli)
                    : "-"
                }}
              </div>
            </div>

            <div class="form-group d-flex align-items-center">
              <label class="form-label">Jumlah</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">Rp</div>
                </div>
                <input
                  type="text"
                  class="form-control text-right"
                  placeholder="Masukkan jumlah"
                  v-model.lazy="formPembelian.jumlah"
                  v-money="configMoney"
                  @keyup="updatePembelian()"
                  :class="{ 'is-invalid': this.formPembelianError }"
                />
              </div>
            </div>
            <div class="form-group d-flex align-items-center">
              <label class="form-label">Deskripsi </label>
              <textarea
                v-model="formPembelian.keterangan"
                rows="3"
                placeholder="Masukkan deskripsi"
                class="form-control"
              ></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" @click="savePembelian" class="btn btn-save">
              Tambah Data
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="!onEdit"
    class="modal fade"
    id="modalAddLocation"
    aria-labelledby="exampleModalLabel"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="card-modal">
          <div class="modal-header">
            <h5 class="modal-title">Tambah Lokasi</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body mt-3">
            <div class="form-group d-flex align-items-center">
              <label class="form-label">Penanggung Jawab </label>
              <Select2
                style="width: 100%"
                v-model="formLokasi.penanggung_jawab"
                :options="OptionResponsiblePerson"
                placeholder="Penanggung Jawab"
                :settings="{dropdownParent: '#modalAddLocation'}"
                @change="formChange('penanggung_jawab', $event, 'lokasi')"
                @select="formChange('penanggung_jawab', $event, 'lokasi')"
                :class="{
                  'is-invalid':
                    formErrorLokasi && formErrorLokasi.penanggung_jawab,
                }"
              />
            </div>
            <div class="form-group d-flex align-items-center">
              <label class="form-label">Lokasi </label>
              <Select2
                style="width: 100%"
                v-model="formLokasi.gudang_transfer"
                :options="OptionWerhouseDestination"
                placeholder="Pilih Lokasi"
                :settings="{dropdownParent: '#modalAddLocation'}"
                @change="myChangeEvent($event)"
                @select="mySelectEvent($event, 'gudang_transfer')"
                :class="{
                  'is-invalid':
                    formErrorLokasi && formErrorLokasi.gudang_transfer,
                }"
              />
            </div>

            <div class="form-group d-flex align-items-center">
              <label class="form-label">Jumlah </label>
              <div class="w-100">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control text-right"
                    placeholder="Jumlah"
                    disabled
                    v-model="formLokasi.jumlah"
                    v-maska="'###########'"
                    @keyup="formChange('jumlah', $event, 'lokasi')"
                    :class="{
                      'is-invalid': formErrorLokasi && formErrorLokasi.jumlah,
                    }"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">{{
                      `${formDetail.nama_satuan}`
                    }}</span>
                  </div>
                </div>
                <div
                  class="form-error"
                  v-if="formErrorLokasi && formErrorLokasi.jumlah"
                >
                  {{ formErrorLokasi.jumlah }}
                </div>
              </div>
            </div>

            <div class="form-group d-flex align-items-center">
              <label class="form-label">Keterangan </label>
              <textarea
                placeholder="Masukkan keterangan"
                v-model="formLokasi.keterangan"
                class="form-control"
              ></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" @click="saveLokasi" class="btn btn-save">
              Tambah Data
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="onEdit"
    class="modal fade"
    id="modalAddTransfer"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="card-modal">
          <div class="modal-header">
            <h5 class="modal-title">Transfer Aset</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body mt-3">
            <div class="form-group row">
              <label class="form-label col-form-label col-12 col-sm-4"
                >Penanggung Jawab
              </label>
              <div class="col-12 col-sm-8">
                <Select2
                  style="width: 100%"
                  v-model="formLokasi.penanggung_jawab"
                  :options="OptionResponsiblePerson"
                  :settings="{dropdownParent: '#modalAddTransfer'}"
                  placeholder="Penanggung Jawab"
                  @change="formChange('penanggung_jawab', $event, 'lokasi')"
                  @select="formChange('penanggung_jawab', $event, 'lokasi')"
                  :class="{
                    'is-invalid':
                      formErrorLokasi && formErrorLokasi.penanggung_jawab,
                  }"
                />
                <div
                  class="form-error"
                  v-if="formErrorLokasi && formErrorLokasi.penanggung_jawab"
                >
                  {{ formErrorLokasi.penanggung_jawab }}
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="form-label col-form-label col-12 col-sm-4"
                >Tanggal</label
              >
              <div class="col-12 col-sm-8">
                <Datepicker
                  :autoApply="true"
                  :closeOnScroll="true"
                  v-model="formLokasi.tanggal"
                  placeholder="Tanggal"
                  :format="format"
                  :enableTimePicker="false"
                  locale="id-ID"
                  selectText="Pilih"
                  cancelText="Batal"
                  :class="{
                    'is-invalid': formErrorLokasi && formErrorLokasi.tanggal,
                  }"
                  @update:modelValue="changeDate($event, 'tanggal', 'lokasi')"
                ></Datepicker>
              </div>
            </div>

            <div class="form-group row">
              <label class="form-label col-form-label col-12 col-sm-4"
                >Lokasi Asal
              </label>
              <div class="col-12 col-sm-8">
                <Select2
                  style="width: 100%"
                  v-model="formLokasi.gudang_awal"
                  :options="OptionWerhouseOrigin"
                  placeholder="Pilih Lokasi Asal"
                  :settings="{dropdownParent: '#modalAddTransfer'}"
                  @change="formChange('gudang_awal', $event, 'lokasi')"
                  @select="formChange('gudang_awal', $event, 'lokasi')"
                  :class="{
                    'is-invalid':
                      formErrorLokasi && formErrorLokasi.gudang_awal,
                  }"
                />
                <div
                  class="form-error"
                  v-if="formErrorLokasi && formErrorLokasi.gudang_awal"
                >
                  {{ formErrorLokasi.gudang_awal }}
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="form-label col-form-label col-12 col-sm-4"
                >Lokasi Tujuan
              </label>
              <div class="col-12 col-sm-8">
                <Select2
                  style="width: 100%"
                  v-model="formLokasi.gudang_transfer"
                  :options="OptionWerhouseDestination"
                  placeholder="Pilih Lokasi Tujuan"
                  :disabled="formLokasi.gudang_awal ? false : true"
                  :settings="{dropdownParent: '#modalAddTransfer'}"
                  @change="formChange('gudang_transfer', $event, 'lokasi')"
                  @select="formChange('gudang_transfer', $event, 'lokasi')"
                  :class="{
                    'is-invalid':
                      formErrorLokasi && formErrorLokasi.gudang_transfer,
                  }"
                />
                <div
                  class="form-error"
                  v-if="formErrorLokasi && formErrorLokasi.gudang_transfer"
                >
                  {{ formErrorLokasi.gudang_transfer }}
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="form-label col-form-label col-12 col-sm-4"
                >Jumlah
              </label>
              <div class="row col-12 col-sm-8 pr-0">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control text-right"
                    placeholder="Masukkan Jumlah"
                    v-model="formLokasi.jumlah"
                    v-maska="'#############'"
                    @keyup="formChange('jumlah', $event, 'lokasi')"
                    :class="{
                      'is-invalid': formErrorLokasi && formErrorLokasi.jumlah,
                    }"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">{{
                      `${formDetail.nama_satuan}`
                    }}</span>
                  </div>
                </div>
                <div
                  class="form-error col-12"
                  v-if="
                    formErrorLokasi &&
                    (formErrorLokasi.jumlah || formLokasi.id_satuan)
                  "
                >
                  {{
                    formErrorLokasi.jumlah
                      ? formErrorLokasi.jumlah
                      : formErrorLokasi.id_satuan
                      ? formErrorLokasi.id_satuan
                      : ""
                  }}
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="form-label col-form-label col-12 col-sm-4"
                >Keterangan
              </label>
              <div class="col-12 col-sm-8">
                <textarea
                  placeholder="Masukkan keterangan"
                  v-model="formLokasi.keterangan"
                  class="form-control"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              @click="setTransfer"
              :disabled="isTransfer"
              class="btn btn-save"
            >
              <span v-if="isTransfer" class="fas fa-spinner fa-spin"></span>
              Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="onEdit"
    class="modal fade"
    id="detailHistory"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="card-modal">
          <div class="modal-header">
            <h5 class="modal-title">Detail History</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body mt-3">
            <div class="form-group d-flex align-items-center">
              <label class="form-label">Tanggal </label>
              <div class="text">{{ historyDetail.tanggal }}</div>
            </div>
            <div class="form-group d-flex align-items-center">
              <label class="form-label">Penanggung Jawab </label>
              <div class="text">{{ historyDetail.penanggung_jawab }}</div>
            </div>

            <div class="form-group d-flex align-items-center">
              <label class="form-label">Kuantitas </label>
              <div class="text">{{ historyDetail.kuantitas }}</div>
            </div>
            <div class="form-group d-flex align-items-center">
              <label class="form-label">Lokasi Asal</label>
              <div class="text">{{ historyDetail.gudang_asal }}</div>
            </div>

            <div class="form-group d-flex align-items-center">
              <label class="form-label">Lokasi Tujuan </label>
              <div class="text">{{ historyDetail.gudang_tujuan }}</div>
            </div>

            <div class="form-group d-flex align-items-center">
              <label class="form-label">Keterangan</label>
              <div class="text">{{ historyDetail.keterangan }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="onEdit"
    class="modal fade"
    id="modalAddPayment"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="card-modal">
          <div class="modal-header">
            <h5 class="modal-title">Pembayaran Hutang</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body mt-3">
            <div class="form-group">
              <label class="form-label">Kas & Bank</label>
              <Select2
                v-model="formPembayaran.id_akun"
                :options="OptionBank"
                placeholder="Pilih akun perkiraan..."
                @change="formChangePembayaran('id_akun')"
                @select="formChangePembayaran('id_akun')"
                :class="{
                  'is-invalid':
                    formErrorPembayaran && formErrorPembayaran.id_akun,
                }"
                :settings="{
                  templateResult: formatState,
                  dropdownParent: '#modalAddPayment'
                }"
              />
              <div
                class="form-error"
                v-if="formErrorPembayaran && formErrorPembayaran.id_akun"
              >
                {{ formErrorPembayaran.id_akun }}
              </div>
            </div>
            <div class="form-group">
              <label>Tanggal Pembayaran :</label>
              <Datepicker
                :autoApply="true"
                :class="{
                  'is-invalid':
                    formErrorPembayaran &&
                    formErrorPembayaran.tanggal_pembayaran,
                }"
                :closeOnScroll="true"
                v-model="formPembayaran.tanggal_pembayaran"
                placeholder="Tanggal Pembayaran"
                :format="format"
                :minDate="formDetail.tanggal_beli"
                :maxDate="new Date()"
                :enableTimePicker="false"
                locale="id-ID"
                selectText="Pilih"
                cancelText="Batal"
                @cleared="changeDatePembayaran('', 'tanggal_pembayaran')"
                @update:modelValue="
                  changeDatePembayaran($event, 'tanggal_pembayaran')
                "
              ></Datepicker>
            </div>
            <div class="form-group">
              <label class="" for="inlineFormInputGroupUsername"
                >Nominal Pembayaran :</label
              >
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">Rp</div>
                </div>
                <input
                  type="text"
                  v-money="configMoney"
                  v-model.lazy="formPembayaran.nilai"
                  :class="{
                    'is-invalid':
                      formErrorPembayaran && formErrorPembayaran.nilai,
                  }"
                  @keyup="formChangePembayaran('nilai')"
                  class="form-control text-right"
                  placeholder="Nominal Pembayaran"
                />
              </div>
              <div
                class="form-error"
                v-if="formErrorPembayaran && formErrorPembayaran.nilai"
              >
                {{ formErrorPembayaran.nilai }}
              </div>
            </div>
            <div class="form-group">
              <label>Nomor Referensi :</label>
              <input
                type="text"
                :class="{
                  'is-invalid':
                    formErrorPembayaran && formErrorPembayaran.no_referensi,
                }"
                @keyup="formChangePembayaran('no_referensi')"
                class="form-control"
                v-model="formPembayaran.no_referensi"
                placeholder="Masukkan Nomor Referensi"
              />
              <div
                class="form-error"
                v-if="formErrorPembayaran && formErrorPembayaran.no_referensi"
              >
                {{ formErrorPembayaran.no_referensi }}
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              @click="savePembayaran"
              :disabled="isPembayaran || formDetail.status_pembayaran == 'paid'"
              class="btn btn-add"
            >
              <span class="fas fa-spinner fa-spin" v-if="isPembayaran"></span>
              Simpan Pembayaran
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="onEdit"
    class="modal fade"
    id="modalJurnalBack"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="card-modal">
          <div class="modal-header">
            <h5 class="modal-title">Jurnal Kembali</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body mt-3">
            <div class="form-group">
              <label>Nomor Pembayaran</label>
              <Select2
                v-model="formJurnalKembali.id_pembayaran"
                :options="optionPembayaran"
                placeholder="Pilih akun perkiraan..."
                @change="formChangeJurnal('id_pembayaran', $event)"
                @select="formChangeJurnal('id_pembayaran', $event)"
                :settings="{dropdownParent: '#modalJurnalBack'}"
                :class="{
                  'is-invalid':
                    formErrorJurnal && formErrorJurnal.id_pembayaran,
                }"
              />
              <div
                class="form-error"
                v-if="formErrorJurnal && formErrorJurnal.id_pembayaran"
              >
                {{ formErrorJurnal.id_pembayaran }}
              </div>
            </div>
            <div class="form-group">
              <label>Tanggal :</label>
              <Datepicker
                :autoApply="true"
                :class="{
                  'is-invalid': formErrorJurnal && formErrorJurnal.tanggal,
                }"
                :closeOnScroll="true"
                v-model="formJurnalKembali.tanggal"
                placeholder="Tanggal"
                :format="format"
                :enableTimePicker="false"
                locale="id-ID"
                selectText="Pilih"
                cancelText="Batal"
                @cleared="changeDateJurnal('', 'tanggal')"
                @update:modelValue="changeDateJurnal($event, 'tanggal')"
              ></Datepicker>
            </div>
            <div class="form-group">
              <label class="" for="inlineFormInputGroupUsername"
                >Nominal :</label
              >
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">Rp</div>
                </div>
                <input
                  type="text"
                  v-money="configMoney"
                  v-model.lazy="formJurnalKembali.nilai"
                  :class="{
                    'is-invalid': formErrorJurnal && formErrorJurnal.nilai,
                  }"
                  @keyup="formChangeJurnal('nilai')"
                  class="form-control text-right"
                  placeholder="Nominal Pembayaran"
                />
              </div>
              <div
                class="form-error"
                v-if="formErrorJurnal && formErrorJurnal.nilai"
              >
                {{ formErrorJurnal.nilai }}
              </div>
            </div>
            <div class="form-group">
              <label>Nomor Referensi :</label>
              <input
                type="text"
                :class="{
                  'is-invalid': formErrorJurnal && formErrorJurnal.no_referensi,
                }"
                @keyup="formChangeJurnal('no_referensi')"
                class="form-control"
                v-model="formJurnalKembali.no_referensi"
                placeholder="Masukkan Nomor Referensi"
              />
              <div
                class="form-error"
                v-if="formErrorJurnal && formErrorJurnal.no_referensi"
              >
                {{ formErrorJurnal.no_referensi }}
              </div>
            </div>
            <div class="form-group">
              <label>Keterangan :</label>
              <textarea
                :class="{
                  'is-invalid': formErrorJurnal && formErrorJurnal.keterangan,
                }"
                @keyup="formChangeJurnal('keterangan')"
                class="form-control"
                v-model="formJurnalKembali.keterangan"
                placeholder="Masukkan Keterangan"
              ></textarea>
              <div
                class="form-error"
                v-if="formErrorJurnal && formErrorJurnal.keterangan"
              >
                {{ formErrorJurnal.keterangan }}
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              @click="saveJurnalBack"
              :disabled="
                isJurnalback ||
                formDetail.status_pembayaran == 'cancel' ||
                formDetail.status_pembayaran == 'unpaid'
              "
              class="btn btn-add"
            >
              <span class="fas fa-spinner fa-spin" v-if="isJurnalback"></span>
              Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="onSubmit">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="" v-if="formDetail.status != 'dijual'">
              <h4 class="title">{{ labelPage }} Aset</h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/data-asset">Aset </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {{ labelPage }}
                  </li>
                </ol>
              </nav>
            </div>
            <div class="" v-else>
              <h4 class="title">Detail Penjualan Aset</h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/data-asset-selling"
                      >Penjualan Aset
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Detail
                  </li>
                </ol>
              </nav>
            </div>
            <div
              class="button-edit"
              v-show="
                validated == 1 &&
                labelPage == 'Edit' &&
                formDetail.status == 'aktif' &&
                isEditable
              "
            >
              <button class="btn-add" type="button" @click="editable">
                <img src="/img/icon-edit1.svg" alt="" style="width: 20px" />
                Edit
              </button>
            </div>
            <div
              v-show="
                (validated != 1 || labelPage == 'Tambah') &&
                formDetail.status == 'aktif' &&
                isEditable
              "
            >
              <button class="btn btn-save" :disabled="isSubmit">
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan
              </button>
            </div>
          </div>

          <div class="card info-detail">
            <img
              :src="getQrcode(formDetail.barcode)"
              v-if="onEdit"
              alt=""
              class="img-barcode"
            />
            <div class="d-flex justify-content-between">
              <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link active"
                    id="pills-home-tab"
                    data-toggle="pill"
                    href="#pills-home"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                    >Detail</a
                  >
                </li>
                <li
                  class="nav-item"
                  role="presentation"
                  v-if="formDetail.payment_type == 'cash'"
                >
                  <a
                    class="nav-link"
                    id="pills-profile-tab"
                    data-toggle="pill"
                    href="#pills-profile"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                    >Pembelian/Ekuitas</a
                  >
                </li>
                <li
                  class="nav-item"
                  role="presentation"
                  v-if="formDetail.payment_type != 'cash' && onEdit"
                >
                  <a
                    class="nav-link"
                    id="pills-pembayaran-tab"
                    data-toggle="pill"
                    href="#pills-pembayaran"
                    role="tab"
                    aria-controls="pills-pembayaran"
                    aria-selected="false"
                    >Pembayaran</a
                  >
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="pills-etc-tab"
                    data-toggle="pill"
                    href="#pills-etc"
                    role="tab"
                    aria-controls="pills-etc"
                    aria-selected="false"
                    >Lokasi</a
                  >
                </li>
                <li class="nav-item" v-if="onEdit" role="presentation">
                  <a
                    class="nav-link"
                    id="pills-histori-tab"
                    data-toggle="pill"
                    href="#pills-histori"
                    role="tab"
                    aria-controls="pills-histori"
                    aria-selected="false"
                    >Histori</a
                  >
                </li>
              </ul>

              <!-- <div class="card-total">
              <div class="total-debit d-flex flex-column">
                <span>Total Aset</span>
                <span class="ml-auto">Rp 16,000,000</span>
              </div>
              <div class="total-kredit d-flex flex-column">
                <span>Nilai Buku</span>
                <span class="ml-auto">Rp 20,000,000</span>
              </div>
            </div> -->
            </div>

            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div class="d-flex mt-3 justify-content-between">
                  <div class="search">
                    <div class="title">Detail Aset</div>
                    <div class="sub-title">
                      {{ labelPage }} informasi yang diperlukan
                    </div>
                  </div>
                </div>
                <hr />

                <div class="row mt-3">
                  <div class="col-12 col-sm-6" v-if="onEdit">
                    <div class="form-group">
                      <label>Kode Aset :</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan Kode Aset"
                        v-model="formDetail.kode"
                        :disabled="
                          onEdit || (validated == 1 && labelPage == 'Edit')
                        "
                      />
                    </div>
                  </div>

                  <div class="col-12 col-sm-6">
                    <div class="form-group">
                      <label>Kategori Aset :</label>
                      <Select2
                        v-model="formDetail.kode_kategori"
                        :options="OptionAssetCategory"
                        placeholder="Pilih Kategori Aset"
                        @change="formChange('kode_kategori', $event, '')"
                        @select="formChange('kode_kategori', $event, '')"
                        :class="{
                          'is-invalid': formError && formError.kode_kategori,
                        }"
                        :disabled="
                          onEdit || (validated == 1 && labelPage == 'Edit')
                        "
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.kode_kategori"
                      >
                        {{ formError.kode_kategori }}
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-sm-6">
                    <div class="form-group">
                      <label>Nama Aset :</label>
                      <input
                        :disabled="validated == 1 && labelPage == 'Edit'"
                        type="text"
                        class="form-control"
                        v-model="formDetail.nama"
                        @keyup="formChange('nama', $event, '')"
                        :class="{ 'is-invalid': formError && formError.nama }"
                        placeholder="Masukkan Nama Aset"
                      />
                    </div>
                    <div class="form-error" v-if="formError && formError.nama">
                      {{ formError.nama }}
                    </div>
                  </div>

                  <div class="col-12 col-sm-6">
                    <div class="form-group">
                      <label>Departemen : </label>
                      <div class="form-group edit-vendor">
                        <Select2
                          v-model="formDetail.id_divisi"
                          :options="OptionDepartement"
                          placeholder="Pilih Departemen"
                          :settings="{
                            settingOption: value,
                            settingOption: value,
                          }"
                          @change="formChange('id_divisi', $event, '')"
                          @select="formChange('id_divisi', $event, '')"
                          :class="{
                            'is-invalid': formError && formError.id_divisi,
                          }"
                          :disabled="
                            onEdit || (validated == 1 && labelPage == 'Edit')
                          "
                        />
                        <div
                          class="form-error"
                          v-if="formError && formError.id_divisi"
                        >
                          {{ formError.id_divisi }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-sm-6">
                    <div class="form-group">
                      <label>Tanggal Beli :</label>
                      <Datepicker
                        :autoApply="true"
                        :closeOnScroll="true"
                        v-model="formDetail.tanggal_beli"
                        :maxDate="new Date()"
                        placeholder="Tanggal"
                        :format="format"
                        :enableTimePicker="false"
                        locale="id-ID"
                        selectText="Pilih"
                        cancelText="Batal"
                        :class="{
                          'is-invalid': formError && formError.tanggal_beli,
                        }"
                        :disabled="
                          onEdit || (validated == 1 && labelPage == 'Edit')
                        "
                        @update:modelValue="changeDate($event, 'tanggal_beli')"
                      ></Datepicker>
                      <div
                        class="form-error"
                        v-if="formError && formError.tanggal_beli"
                      >
                        {{ formError.tanggal_beli }}
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-sm-6">
                    <div class="form-group">
                      <label>Jumlah :</label>
                      <div class="row">
                        <div class="col-md-8 col-sm-7 col-7 pr-0">
                          <input
                            type="text"
                            class="form-control text-right"
                            placeholder="Masukkan Jumlah Aset"
                            v-maska="'##########'"
                            v-model="formDetail.qty"
                            :class="{
                              'is-invalid': formError && formError.qty,
                            }"
                            :disabled="true"
                          />
                          <!-- disabled="
                              onEdit || (validated == 1 && labelPage == 'Edit')
                            " -->
                        </div>
                        <div class="col-md-4 col-sm-5 col-5 pl-0">
                          <Select2
                            :disabled="validated == 1 && labelPage == 'Edit'"
                            v-model="formDetail.id_satuan"
                            :options="OptionUnit"
                            placeholder="Satuan"
                            @change="formChange('id_satuan', $event)"
                            @select="formChange('id_satuan', $event)"
                            :class="{
                              'is-invalid': formError && formError.id_satuan,
                            }"
                          />
                          <input
                            type="text"
                            v-model="formDetail.nama_satuan"
                            placeholder="Satuan Baru"
                            :class="{
                              'is-invalid': formError && formError.nama_satuan,
                            }"
                            v-if="formDetail.id_satuan == 'new'"
                            class="form-control mt-2"
                          />
                        </div>
                      </div>
                      <div
                        class="form-error"
                        v-if="
                          (formError && formError.qty) ||
                          (formError && formError.id_satuan)
                        "
                      >
                        {{
                          formError.qty
                            ? formError.qty
                            : formError.id_satuan
                            ? formError.id_satuan
                            : ""
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6">
                    <div class="form-group">
                      <label>Jenis Pembayaran :</label>
                      <Select2
                        :disabled="
                          onEdit || (validated == 1 && labelPage == 'Edit')
                        "
                        v-model="formDetail.payment_type"
                        :options="optionPaymentType"
                        placeholder="Jenis Pembayaran"
                        @change="formChange('payment_type', $event)"
                        @select="formChange('payment_type', $event)"
                        :class="{
                          'is-invalid': formError && formError.payment_type,
                        }"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.payment_type"
                      >
                        {{ formError.payment_type }}
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-12 col-sm-6"
                    v-if="formDetail.payment_type == 'cicilan'"
                  >
                    <div class="form-group">
                      <label>Akun Hutang :</label>
                      <Select2
                        v-model="formDetail.beban_hutang"
                        :options="OptionAccoountDebt"
                        placeholder="Pilih Akun Hutang"
                        :disabled="onEdit"
                        :settings="{
                          templateResult: formatState,
                        }"
                        @change="formChange('beban_hutang', $event, '')"
                        @select="formChange('beban_hutang', $event, '')"
                        :class="{
                          'is-invalid': formError && formError.beban_hutang,
                        }"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.beban_hutang"
                      >
                        {{ formError.beban_hutang }}
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-12 col-sm-6"
                    v-if="!onEdit && formDetail.payment_type == 'cicilan'"
                  >
                    <div class="form-group">
                      <label class="">Nilai Aset :</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <div
                            class="input-group-text disabled"
                            style="background-color: #f3f4f4"
                          >
                            Rp
                          </div>
                        </div>
                        <input
                          v-model="formDetail.nilai_aset"
                          type="text"
                          class="form-control text-right"
                          placeholder="Masukkan Nilai Buku"
                          @keyup="formChange('nilai_aset', $event)"
                          v-money="configMoney"
                          :class="{
                            'is-invalid': formError && formError.nilai_aset,
                          }"
                        />
                      </div>
                      <div
                        class="form-error"
                        v-if="formError && formError.nilai_aset"
                      >
                        {{ formError.nilai_aset }}
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-12 col-sm-6"
                    v-if="formDetail.payment_type == 'cicilan'"
                  >
                    <div class="form-group">
                      <label class="">Tenor Cicilan :</label>
                      <div class="input-group">
                        <input
                          v-model="formDetail.tenor"
                          type="text"
                          class="form-control text-right"
                          placeholder="Masukkan Tenor"
                          :disabled="onEdit"
                          @keyup="formChange('tenor', $event)"
                          v-maska="'############'"
                          :class="{
                            'is-invalid': formError && formError.tenor,
                          }"
                        />
                        <div class="input-group-prepend">
                          <div
                            class="input-group-text disabled"
                            style="background-color: #f3f4f4"
                          >
                            Bulan
                          </div>
                        </div>
                      </div>
                      <div
                        class="form-error"
                        v-if="formError && formError.tenor"
                      >
                        {{ formError.tenor }}
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-12 col-sm-6"
                    v-if="formDetail.payment_type == 'cicilan'"
                  >
                    <div class="form-group">
                      <label class="">Cicilan Perbulan :</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <div
                            class="input-group-text"
                            style="background-color: #f3f4f4"
                          >
                            Rp
                          </div>
                        </div>
                        <input
                          disabled
                          type="text"
                          v-model="cicilanPer"
                          class="form-control text-right"
                          placeholder="Masukkan Nilai Aset"
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-12 col-sm-6"
                    v-if="formDetail.payment_type == 'cicilan' && onEdit"
                  >
                    <div class="form-group">
                      <label class="">Pembayaran Awal/DP :</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <div
                            class="input-group-text"
                            style="background-color: #f3f4f4"
                          >
                            Rp
                          </div>
                        </div>
                        <input
                          disabled
                          type="text"
                          v-model="formDetail.nilai_dp"
                          class="form-control text-right"
                          placeholder="Masukkan Nilai Aset"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-sm-6" v-if="onEdit">
                    <div class="form-group">
                      <label class="">Nilai Buku :</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <div
                            class="input-group-text disabled"
                            style="background-color: #f3f4f4"
                          >
                            Rp
                          </div>
                        </div>
                        <input
                          disabled
                          v-model="bookValue"
                          type="text"
                          class="form-control text-right"
                          placeholder="Masukkan Nilai Buku"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-sm-6" v-if="onEdit">
                    <div class="form-group">
                      <label class="">Nilai Aset/Pembelian :</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <div
                            class="input-group-text"
                            style="background-color: #f3f4f4"
                          >
                            Rp
                          </div>
                        </div>
                        <input
                          disabled
                          type="text"
                          v-model="assetValue"
                          class="form-control text-right"
                          placeholder="Masukkan Nilai Aset"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-sm-6" v-if="onEdit">
                    <div class="form-group">
                      <label class="">Umur Aset</label>
                      <input
                        disabled
                        v-model="oldAsset"
                        type="text"
                        class="form-control text-right"
                        placeholder="Umur Aset"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>File</label>
                      <fileUpload
                        :disabled="onEdit"
                        :file="listFile"
                        @update="setFile"
                        @delete="setDelete"
                      />
                    </div>
                  </div>
                </div>

                <div
                  class="row mt-2"
                  v-if="!onEdit && formDetail.payment_type == 'cicilan'"
                >
                  <div class="col-12">
                    <div class="title">Pembayaran Awal</div>
                    <div class="sub-title">
                      Tambah informasi pembayaran awal
                    </div>
                  </div>
                </div>
                <hr v-if="!onEdit && formDetail.payment_type == 'cicilan'" />
                <div
                  class="row mt-2"
                  v-if="!onEdit && formDetail.payment_type == 'cicilan'"
                >
                  <div class="col-12 col-sm-6">
                    <div class="form-group">
                      <label class="">Nominal Pembayaran awal :</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <div
                            class="input-group-text disabled"
                            style="background-color: #f3f4f4"
                          >
                            Rp
                          </div>
                        </div>
                        <input
                          v-model="formDetail.nilai_dp"
                          type="text"
                          class="form-control text-right"
                          placeholder="Masukkan Nilai DP Pembelian"
                          v-money="configMoney"
                          @keyup="formChange('nilai_dp', $event)"
                          :class="{
                            'is-invalid': formError && formError.nilai_dp,
                          }"
                        />
                      </div>
                      <div
                        class="form-error"
                        v-if="formError && formError.nilai_dp"
                      >
                        {{ formError.nilai_dp }}
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-sm-6">
                    <div class="form-group">
                      <label>Akun Bank :</label>
                      <Select2
                        v-model="formDetail.akun_bank"
                        :options="OptionAccountEstimated"
                        placeholder="Pilih Akun Bank"
                        :disabled="onEdit"
                        :settings="{
                          templateResult: formatState,
                        }"
                        @change="formChange('akun_bank', $event, '')"
                        @select="formChange('akun_bank', $event, '')"
                        :class="{
                          'is-invalid': formError && formError.akun_bank,
                        }"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.akun_bank"
                      >
                        {{ formError.akun_bank }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-12">
                    <div class="title">Informasi Aset</div>
                    <div class="sub-title">
                      {{ labelPage }} informasi yang diperlukan
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row mt-3">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Jenis Aset :</label>
                      <div class="form-group edit-vendor">
                        <Select2
                          v-model="formDetail.berwujud"
                          :options="OptionIntangibleAssets"
                          placeholder="Pilih jenis Aset"
                          :settings="{
                            settingOption: value,
                            settingOption: value,
                          }"
                          :disabled="onEdit"
                          @change="formChange('berwujud', $event, '')"
                          @select="formChange('berwujud', $event, '')"
                          :class="{
                            'is-invalid': formError && formError.berwujud,
                          }"
                        />
                        <div
                          class="form-error"
                          v-if="formError && formError.berwujud"
                        >
                          {{ formError.berwujud }}
                        </div>
                      </div>
                    </div>

                    <div class="form-group">
                      <label>Akun Aset : </label>
                      <div class="form-group edit-vendor">
                        <Select2
                          :disabled="onEdit"
                          v-model="formDetail.akun_aset"
                          :options="OptionAssetAccount"
                          placeholder="Pilih akun perkiraan"
                          @change="formChange('akun_aset', $event, '')"
                          @select="formChange('akun_aset', $event, '')"
                          :settings="{ templateResult: formatState }"
                          :class="{
                            'is-invalid': formError && formError.akun_aset,
                          }"
                        />
                        <div
                          class="form-error"
                          v-if="formError && formError.akun_aset"
                        >
                          {{ formError.akun_aset }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-6">
                    <div class="form-group">
                      <label>Akun Akumulasi Penyusutan : </label>
                      <div class="form-group edit-vendor">
                        <Select2
                          :disabled="onEdit"
                          v-model="formDetail.akun_akumulasi_penyusutan"
                          :options="OptionAccumulatedDepreciationAccount"
                          :settings="{ templateResult: formatState }"
                          placeholder="Pilih akun perkiraan"
                          @change="
                            formChange('akun_akumulasi_penyusutan', $event, '')
                          "
                          @select="
                            formChange('akun_akumulasi_penyusutan', $event, '')
                          "
                          :class="{
                            'is-invalid':
                              formError && formError.akun_akumulasi_penyusutan,
                          }"
                        />
                        <div
                          class="form-error"
                          v-if="
                            formError && formError.akun_akumulasi_penyusutan
                          "
                        >
                          {{ formError.akun_akumulasi_penyusutan }}
                        </div>
                      </div>
                    </div>

                    <div class="form-group">
                      <label>Akun Beban Penyusutan : </label>
                      <div class="form-group edit-vendor">
                        <Select2
                          :disabled="onEdit"
                          v-model="formDetail.akun_beban_penyusutan"
                          :options="OptionDepreciationExpenseAccount"
                          :settings="{ templateResult: formatState }"
                          placeholder="Pilih akun perkiraan"
                          @change="
                            formChange('akun_beban_penyusutan', $event, '')
                          "
                          @select="
                            formChange('akun_beban_penyusutan', $event, '')
                          "
                          :class="{
                            'is-invalid':
                              formError && formError.akun_beban_penyusutan,
                          }"
                        />
                        <div
                          class="form-error"
                          v-if="formError && formError.akun_beban_penyusutan"
                        >
                          {{ formError.akun_beban_penyusutan }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-12">
                    <div class="title">Penyusutan Aset</div>
                    <div class="sub-title">
                      {{ labelPage }} informasi yang diperlukan
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row mt-3">
                  <div class="col-12 col-sm-6">
                    <div class="form-group">
                      <label>Metode Penyusutan :</label>
                      <div class="form-group edit-vendor">
                        <Select2
                          :disabled="onEdit"
                          v-model="formDetail.metode_penyusutan"
                          :options="OptionShrinkageMethod"
                          placeholder="Pilih Metode Penyusutan"
                          :settings="{
                            settingOption: value,
                            settingOption: value,
                          }"
                          @change="formChange('metode_penyusutan', $event, '')"
                          @select="formChange('metode_penyusutan', $event, '')"
                          :class="{
                            'is-invalid':
                              formError && formError.metode_penyusutan,
                          }"
                        />
                        <div
                          class="form-error"
                          v-if="formError && formError.metode_penyusutan"
                        >
                          {{ formError.metode_penyusutan }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6">
                    <div class="form-group">
                      <label>Estimasi Penggunaan : </label>
                      <div class="row">
                        <div class="col-6">
                          <div class="input-group">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Masukan tahun"
                              v-model="formDetail.est_penggunaan_tahun"
                              :class="{
                                'is-invalid':
                                  formError && formError.est_penggunaan_tahun,
                              }"
                              :disabled="onEdit"
                              v-maska="'###########'"
                              @keyup="formChange('est_penggunaan_tahun')"
                            />
                            <div class="input-group-append">
                              <span class="input-group-text" id="basic-addon2"
                                >Tahun</span
                              >
                            </div>
                          </div>
                          <div
                            class="form-error"
                            v-if="formError && formError.est_penggunaan_tahun"
                          >
                            {{ formError.est_penggunaan_tahun }}
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="input-group">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Masukan Bulan"
                              v-model="formDetail.est_penggunaan_bulan"
                              @keyup="formChange('est_penggunaan_bulan')"
                              v-maska="'###########'"
                              :disabled="onEdit"
                            />
                            <div class="input-group-append">
                              <span class="input-group-text" id="basic-addon2"
                                >Bulan</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-sm-6">
                    <div class="row">
                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label class="">Rasio :</label>
                          <div class="input-group">
                            <input
                              type="text"
                              class="form-control text-right"
                              placeholder="Masukkan Rasio Aset"
                              disabled="true"
                              v-model="rasio"
                            />
                            <div class="input-group-append">
                              <div class="input-group-text">%</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label>Tanggal Pakai :</label>
                          <Datepicker
                            :autoApply="true"
                            :closeOnScroll="true"
                            v-model="formDetail.tanggal_pakai"
                            placeholder="Tanggal"
                            :format="format"
                            :enableTimePicker="false"
                            locale="id-ID"
                            selectText="Pilih"
                            cancelText="Batal"
                            :disabled="onEdit"
                            :class="{
                              'is-invalid':
                                formError && formError.tanggal_pakai,
                            }"
                            @update:modelValue="
                              changeDate($event, 'tanggal_pakai')
                            "
                          ></Datepicker>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-sm-6">
                    <div class="form-group">
                      <label class="">Nilai Residu :</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <div class="input-group-text">Rp</div>
                        </div>
                        <input
                          :disabled="validated == 1 && labelPage == 'Edit'"
                          type="text"
                          class="form-control"
                          placeholder="Masukkan Nilai Residu"
                          v-model.lazy="formDetail.nilai_residu"
                          v-money="configMoney"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="formDetail.status == 'dijual'">
                  <div class="row mt-2">
                    <div class="col-12">
                      <div class="title">Penjualan Aset</div>
                      <div class="sub-title">
                        Detail informasi penjualan aset
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="row mt-3">
                    <div class="col-12 col-sm-6">
                      <div class="form-group">
                        <label>Tanggal Jual :</label>
                        <Datepicker
                          :autoApply="true"
                          :closeOnScroll="true"
                          v-model="dataPenjualan.tanggal_jual"
                          placeholder="Tanggal Jual"
                          :format="format"
                          :enableTimePicker="false"
                          locale="id-ID"
                          selectText="Pilih"
                          cancelText="Batal"
                          :disabled="true"
                          :class="{
                            'is-invalid': formError && formError.tanggal_pakai,
                          }"
                          @update:modelValue="
                            changeDate($event, 'tanggal_pakai')
                          "
                        ></Datepicker>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6">
                      <div class="form-group">
                        <label>Nilai Jual :</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text"> Rp </span>
                          </div>
                          <input
                            type="text"
                            name="nilai_jual"
                            disabled
                            id="nilai_jual"
                            v-model="dataPenjualan.nilai_jual"
                            class="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6">
                      <div class="form-group">
                        <label>Akun Bank :</label>
                        <input
                          type="text"
                          name="akun_bank_penjualan"
                          id="akun_bank_penjualan"
                          v-model="dataPenjualan.nama_akun_bank"
                          disabled
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-sm-6">
                      <div class="form-group">
                        <label>Akun Penghentian Aset :</label>
                        <input
                          type="text"
                          name="akun_penghentian_aset"
                          id="akun_penghentian_aset"
                          v-model="dataPenjualan.nama_akun_penghentian_aset"
                          disabled
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-sm-6">
                      <div class="form-group">
                        <label>Catatan</label>
                        <textarea
                          name="catatan_pembeli"
                          id="catatan_pembeli"
                          disabled
                          v-model="dataPenjualan.keterangan"
                          rows="3"
                          class="form-control"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="formDetail.payment_type == 'cash'"
                class="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <div class="d-flex mt-3 justify-content-between">
                  <div class="search">
                    <div class="title">Pembelian/Ekuitas</div>
                    <div class="sub-title">
                      {{ labelPage }} informasi yang diperlukan
                    </div>
                  </div>
                </div>
                <hr />

                <div class="search">
                  <div class="form-group" style="width: 400px" v-if="!onEdit">
                    <Select2
                      v-model="AccountEstimated"
                      :options="OptionAccountEstimated"
                      placeholder="Pilih akun perkiraan..."
                      :settings="{ templateResult: formatState }"
                      @change="myChangeEvent($event)"
                      @select="mySelectFormState($event)"
                    />
                  </div>
                </div>

                <div class="mt-4">
                  <table
                    class="table table-bordered table-striped"
                    id="tablePembelian"
                  >
                    <thead>
                      <tr>
                        <th style="width: 15%">Kode</th>
                        <th style="width: 20%">Nama Akun</th>
                        <th style="width: 15%">Tanggal</th>
                        <th style="width: 20%">Jumlah</th>
                        <th style="width: 20%">Deskripsi</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(value, index) in listPembelian" :key="index">
                        <td>{{ value.id_akun }}</td>
                        <td>{{ value.nama_akun }}</td>
                        <td>
                          {{
                            this.formDetail.tanggal_beli && !onEdit
                              ? convertDate(this.formDetail.tanggal_beli)
                              : onEdit && value.tanggal
                              ? convertDate(value.tanggal)
                              : "-"
                          }}
                        </td>
                        <td class="text-right">
                          {{ formatMoney(value.jumlah) }}
                        </td>
                        <td class="position-relative">
                          {{ value.keterangan ? value.keterangan : "-" }}
                          <div
                            style="
                              position: absolute;
                              right: -30px;
                              top: 50%;
                              transform: translateY(-50%);
                            "
                          >
                            <img
                              src="/img/avatar/icon-delete.svg"
                              v-if="!onEdit"
                              @click="deletePembelian(index)"
                              class="btn-add-row ml-1"
                              style="cursor: pointer; width: 20px"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr v-if="listPembelian.length === 0">
                        <td colspan="5" class="text-center">Tidak ada data</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                v-if="formDetail.payment_type == 'cicilan' && onEdit"
                class="tab-pane fade"
                id="pills-pembayaran"
                role="tabpanel"
                aria-labelledby="pills-pembayaran-tab"
              >
                <div class="row mt-3">
                  <div class="search col-6">
                    <div class="title">Pembayaran Aset</div>
                    <div class="sub-title">Tambahkan data pembayaran aset</div>
                  </div>
                  <div class="col-6 d-flex align-items-end justify-content-end">
                    <div
                      class="text-capitalize"
                      style="min-width: 80px"
                      :class="{
                        'red-bedge': formDetail.status_pembayaran != 'paid',
                        'green-bedge': formDetail.status_pembayaran == 'paid',
                      }"
                    >
                      {{ formDetail.status_pembayaran }}
                    </div>
                  </div>
                </div>
                <hr />

                <div class="row">
                  <div class="col-6 card-total">
                    <div class="total-debit d-flex flex-column">
                      <span>Sisa Pembayaran</span>
                      <span class="ml-auto"
                        >Rp
                        {{
                          formatMoney(
                            this.sisaPembayaran.toString().split(".").join("")
                          )
                        }}</span
                      >
                    </div>
                  </div>
                  <div
                    class="col-6 card-total justify-content-end align-items-center"
                  >
                    <button
                      type="button"
                      class="btn btn-download mr-2"
                      v-show="
                        formDetail.status_pembayaran != 'cancel' &&
                        formDetail.status_pembayaran != 'unpaid'
                      "
                      @click="flipJournal"
                    >
                      Jurnal Kembali
                    </button>
                    <button
                      class="btn btn-add"
                      v-if="formDetail.status_pembayaran != 'paid'"
                      type="button"
                      @click="createPembayaran"
                      style="
                        font-size: 13px !important;
                        padding: 0.3rem 0.8rem !important;
                      "
                    >
                      Tambah Pembayaran
                    </button>
                    <!-- <button class="btn btn-print-invoice" data-toggle="modal"
                                                    data-target="#modalPrintInvoice">Cetak Invoice</button> -->
                  </div>
                </div>
                <div class="table-responsive mt-3">
                  <table
                    class="table table-bordered table-striped table-hover"
                    id="tableDepartment"
                  >
                    <thead>
                      <tr>
                        <th style="width: 15%">Nomor Pembayaran</th>
                        <th style="width: 20%">Tanggal</th>
                        <th style="width: 20%">Jumlah</th>
                        <th style="width: 10%">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(value, index) in listCicilan"
                        :key="index"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Klik untuk melihat detail"
                      >
                        <td>{{ value.no_transaksi }}</td>
                        <td>{{ formatDate(value.tanggal_pembayaran) }}</td>
                        <td class="text-right">
                          {{ formatMoney(value.nilai) }}
                        </td>
                        <td>
                          <div
                            class="green-bedge"
                            v-if="value.tipe == 'pembayaran'"
                          >
                            Paid
                          </div>
                          <div class="orange-bedge" v-else>Jurnal Kembali</div>
                        </td>
                      </tr>
                      <tr v-if="listCicilan.length === 0">
                        <td colspan="5" class="text-center">Tidak ada data</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="pills-etc"
                role="tabpanel"
                aria-labelledby="pills-etc-tab"
              >
                <div class="mt-3">
                  <div class="search">
                    <div class="title">Lokasi Aset</div>
                    <div class="sub-title">
                      {{ labelPage }} informasi yang diperlukan
                    </div>
                  </div>
                </div>
                <hr />
                <div class="mt-3">
                  <div class="text-right">
                    <button
                      class="btn-add"
                      type="button"
                      @click="showAddLocation"
                      v-if="!onEdit && listLokasi.length == 0"
                    >
                      + Tambah Lokasi
                    </button>
                    <button
                      v-else-if="
                        onEdit &&
                        formDetail.status == 'aktif' &&
                        (checkAccess('asset') ||
                          checkAccess('asset', 'cru_location_asset'))
                      "
                      class="btn btn-save mr-3"
                      type="button"
                      @click="showTransfer"
                    >
                      <img
                        src="/img/icon-transfer.svg"
                        alt=""
                        class="mr-2"
                      />Transfer
                    </button>
                  </div>

                  <div class="mt-3">
                    <table
                      class="table table-bordered table-striped"
                      id="TableLokasi"
                    >
                      <thead>
                        <tr>
                          <th style="width: 30%">Lokasi</th>
                          <th style="width: 20%">Penanggung Jawab</th>
                          <th style="width: 40%">Alamat</th>
                          <th style="width: 2%">Jumlah</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(value, index) in listLokasi" :key="index">
                          <td>{{ value.nama_gudang }}</td>
                          <td>{{ value.nama_penanggung_jawab }}</td>
                          <td>
                            {{ value.alamat }}
                          </td>
                          <td class="position-relative">
                            {{ `${value.jumlah} ${formDetail.nama_satuan}` }}
                            <div
                              style="
                                position: absolute;
                                right: -30px;
                                top: 50%;
                                transform: translateY(-50%);
                              "
                            >
                              <img
                                src="/img/avatar/icon-delete.svg"
                                v-if="!onEdit"
                                @click="deleteLokasi(index)"
                                class="btn-add-row ml-1"
                                style="cursor: pointer; width: 20px"
                              />
                            </div>
                          </td>
                        </tr>
                        <tr v-if="listLokasi.length == 0">
                          <td colspan="4" class="text-center">
                            Tidak ada data
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div
                v-if="onEdit"
                class="tab-pane fade"
                id="pills-histori"
                role="tabpanel"
                aria-labelledby="pills-histori-tab"
              >
                <div class="mt-3">
                  <div class="search">
                    <div class="title">History Lokasi Aset</div>
                    <div class="sub-title">Edit informasi yang diperlukan</div>
                  </div>
                </div>
                <hr />
                <div class="table-responsive mt-3">
                  <table
                    class="table table-bordered table-striped table-hover"
                    id="tableHistoryAddress"
                  >
                    <thead>
                      <tr>
                        <th style="width: 15%">Tanggal</th>
                        <th style="width: 30%">Lokasi Asal</th>
                        <th style="width: 30%">Lokasi Tujuan</th>
                        <th style="width: 5%">Kuantitas</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(value, index) in listHistory"
                        :key="index"
                        v-on:click="
                          detailHistory(
                            value.tanggal,
                            value.nama_penanggung_jawab,
                            value.qty,
                            value.nama_satuan,
                            value.nama_gudang_awal,
                            value.nama_gudang_transfer,
                            value.keterangan
                          )
                        "
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Klik untuk edit"
                      >
                        <td>{{ convertDate(value.tanggal) }}</td>
                        <td>{{ value.nama_gudang_awal }}</td>
                        <td>
                          {{
                            value.nama_gudang_transfer
                              ? value.nama_gudang_transfer
                              : "-"
                          }}
                        </td>
                        <td>{{ `${value.qty} ${value.nama_satuan}` }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../components/Sidebar.vue";
import NavbarDashboard from "../../../components/Navbar.vue";
import Select2 from "vue3-select2-component";
import Datepicker from "vue3-date-time-picker";
import { ref } from "vue";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";
import {
  checkRules,
  cksClient,
  showAlert,
  checkModuleAccess,
  checkFileOrImageFromLink,
} from "../../../helper";
import moment from "moment";
import "moment/locale/id";
import { VMoney } from "v-money";
import { maska } from "maska";
import { get_KaryawanList } from "../../../actions/karyawan";
import { get_ListDivisi, get_SatuanList } from "../../../actions/master";
import { get_GudangList } from "../../../actions/company/gudang";
import { get_KategoriAsetList } from "../../../actions/aset/kategori";
import { get_AkunList } from "../../../actions/akun_perkiraan";
import {
  get_MetodePenyustanList,
  post_AssetSave,
  get_AsetDetail,
  post_AssetLokasiSave,
  post_AssetSavePembayaran,
  post_AssetJurnalBack,
} from "../../../actions/aset";
import { DATA } from "../../../constans/Url";
import fileUpload from "../components/fileUpload.vue";

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    Datepicker,
    fileUpload
    // TableLite
  },

  directives: {
    maska,
    money: VMoney,
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },

  data() {
    return {
      isEditable: true,
      checkAccess: checkModuleAccess,
      accessDepartement: cksClient().get("_account").akses_departement,
      validated: 1,
      kuantitas: "2",
      address: "",
      bookValue: "",
      assetValue: "",
      OptionIntangibleAssets: [
        { id: "ya", text: "Berwujud" },
        { id: "tidak", text: "Tidak Berwujud" },
      ],
      AccountEstimated: "",
      OptionResponsiblePerson: [],
      OptionUnit: [],
      OptionWerhouseOrigin: [],
      OptionWerhouseDestination: [],
      OptionDepartement: [],
      OptionShrinkageMethod: [],
      OptionAssetCategory: [],
      OptionAssetAccount: [],
      OptionAccumulatedDepreciationAccount: [],
      OptionDepreciationExpenseAccount: [],
      OptionAccountEstimated: [],
      OptionAccoountDebt: [],
      OptionBank: [],
      optionPaymentType: [
        { id: "cash", text: "Cash" },
        { id: "cicilan", text: "Cicilan" },
      ],
      labelPage: this.$route.params.id ? "Edit" : "Tambah",
      oldAsset: "",
      configMoney: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false /* doesn't work with directive */,
      },
      isSubmit: false,
      //formData
      onEdit: false,
      id_company: cksClient().get("_account").id_company,
      kodeAset: this.$route.params.id ? this.$route.params.id : "",
      formError: [],
      listFile: [],
      fileDelete: [],
      formDetail: {
        kode: this.$route.params.id ? this.$route.params.id : "",
        nama: "",
        id_satuan: "",
        nama_satuan: "",
        id_divisi: "",
        id_company: cksClient().get("_account").id_company,
        kode_kategori: "",
        tanggal_beli: "",
        tanggal_pakai: "",
        berwujud: "ya",
        qty: "1",
        akun_aset: "",
        akun_akumulasi_penyusutan: "",
        akun_beban_penyusutan: "",
        est_penggunaan_tahun: "",
        est_penggunaan_bulan: "",
        barcode: "",
        nilai_residu: "",
        metode_penyusutan: "",
        payment_type: "cash",
        status: "aktif",
        status_pembayaran: "unpaid",
        nilai_aset: 0,
        beban_hutang: "",
        tenor: 0,
        beban_dp: "",
        nilai_dp: "",
        akun_bank: "",
        tanggal_bayar: "",
      },
      rules: {
        nama: {
          required: true,
        },
        id_satuan: {
          required: true,
        },
        nama_satuan: {
          required: true,
        },
        payment_type: {
          required: true,
        },
        id_divisi: {
          required: false,
        },
        kode_kategori: {
          required: true,
        },
        tanggal_beli: {
          required: true,
        },
        tanggal_pakai: {
          required: true,
        },
        berwujud: {
          required: true,
        },
        qty: {
          required: true,
        },
        akun_aset: {
          required: true,
        },
        akun_akumulasi_penyusutan: {
          required: true,
        },
        akun_beban_penyusutan: {
          required: true,
        },
        est_penggunaan_tahun: {
          required: true,
        },
        est_penggunaan_bulan: {
          required: false,
        },
        nilai_residu: {
          required: false,
        },
        metode_penyusutan: {
          required: true,
        },
      },
      cicilanPer: 0,
      rasio: "",
      //Pembelian
      listPembelian: [],
      formPembelian: {
        id_akun: "",
        nama_akun: "",
        tanggal: "",
        keterangan: "",
        jumlah: "",
      },
      formPembelianError: false,
      //Lokasi
      listLokasi: [],
      formLokasi: {
        penanggung_jawab: "",
        nama_penanggung_jawab: "",
        gudang_awal: "",
        gudang_transfer: "",
        nama_gudang: "",
        alamat: "",
        jumlah: "1",
        id_satuan: "",
        text_satuan: "",
        tanggal: "",
        keterangan: "",
      },
      formErrorLokasi: [],
      rulesLokasi: {
        penanggung_jawab: {
          required: true,
        },
        gudang_awal: {
          required: false,
        },
        gudang_transfer: {
          required: true,
          changed: true,
        },
        alamat: {
          required: false,
        },
        jumlah: {
          required: true,
          max: 1000000000000,
        },
        text_satuan: {
          required: false,
        },
        tanggal: {
          required: false,
        },
      },
      //History Lokasi
      listHistory: [],
      isTransfer: false,
      historyDetail: {
        tanggal: "",
        penanggung_jawab: "",
        kuantitas: "",
        gudang_asal: "",
        gudang_tujuan: "",
        keterangan: "",
      },
      //CICILAN
      listCicilan: [],
      sisaPembayaran: 0,
      formPembayaran: {
        no_transaksi: "",
        kode_aset: this.$route.params.id ? this.$route.params.id : "",
        tanggal_pembayaran: "",
        no_referensi: "",
        status: "aktif",
        nilai: 0,
        id_akun: "",
      },
      formErrorPembayaran: [],
      rulesPembayaran: {
        nilai: {
          required: true,
          min: 1,
          max: 100000,
        },
        tanggal_pembayaran: {
          required: true,
        },
        id_akun: {
          required: true,
        },
      },

      //jurnal kembali
      isJurnalback: false,
      optionPembayaran: [],
      formJurnalKembali: {
        kode_aset: this.$route.params.id ? this.$route.params.id : "",
        id_pembayaran: "",
        akun_kas: "",
        tanggal: "",
        nilai: "",
        no_referensi: "",
        keterangan: "",
      },
      rulesJurnalKembali: {
        id_pembayaran: {
          required: true,
        },
        tanggal: {
          required: true,
        },
        nilai: {
          required: true,
          min: 1,
          max: 0,
        },
        no_referensi: {
          required: false,
        },
        keterangan: {
          required: false,
        },
      },
      formErrorJurnal: [],
      dataPenjualan: {
        tanggal_jual: "",
        nilai_jual: 0,
        keterangan: "",
        nama_akun_bank: "",
        nama_akun_penghentian_aset: "",
      },
    };
  },

  mounted() {
    // $("#tablePembelian").DataTable({
    //   info: false,
    // });

    // $("#TableLokasi").DataTable({
    //   info: false,
    // });

    $("#tableHistoryAddress").DataTable({
      info: false,
    });

    setTimeout(() => {
      $("#pills-home-tab").tab("show");
    }, 600);

    this.tooltip();
  },

  unmounted() {
    $("#pills-home-tab").tab("show");
  },

  created() {
    if (this.labelPage == "Tambah" && this.accessDepartement) {
      var data = this.accessDepartement.split("|");
      this.formDetail.id_divisi = data[0];
    }
    this.getKaryawan();
    this.getSatuan();
    this.getGudang();
    this.getDivisi();
    this.getCategoryAsset();
    this.getAkun("4,5,7,20");
    this.getAkun("6");
    this.getAkun("14,15,13");
    this.getAkun("1");
    this.getAkun("1,11", false);
    this.getAkun("8,9,10"), this.getShrinkMethod();
    if (this.kodeAset) {
      this.getData();
    }

    if (!this.checkAccess("asset", "cru_asset")) {
      // this.validated = 3;
      this.isEditable = false;
    }
  },
  methods: {
    showButtonSave(data) {
      if (!this.checkAccess("asset", "cru_asset")) {
        // this.validated = 3;
        this.isEditable = false;
      }
      if (data == 1) {
        this.validated = 1;
      } else {
        this.validated = 2;
      }
    },
    editable() {
      this.validated = 2;
    },
    setFile(e) {
      this.listFile = e;
    },
    setDelete(e) {
      if (e) {
        this.fileDelete.push(e);
      }
    },
    // master
    getData() {
      get_AsetDetail(
        this.kodeAset,
        (res) => {
          var {
            data,
            lokasi,
            pembelian,
            history,
            nilai,
            lokasiGudang,
            cicilan,
            penjualan,
            files
          } = res;
          if (penjualan) {
            penjualan.nilai_jual = this.formatMoney(penjualan.nilai_jual)
            this.dataPenjualan = penjualan;
          }
          if (data.status == "aktif") {
            this.oldAsset = this.countAgeOfAsset(
              data.tanggal_pakai,
              moment().format("YYYY-MM-DD")
            );
          } else if (data.status == "dijual" && penjualan) {
            this.oldAsset = this.countAgeOfAsset(
              data.tanggal_pakai,
              moment(penjualan.tanggal_jual).format("YYYY-MM-DD")
            );
          } else {
            this.oldAsset = this.countAgeOfAsset(
              data.tanggal_pakai,
              moment(data.updated_at).format("YYYY-MM-DD")
            );
          }
          delete data.created_at;
          delete data.updated_at;
          data.tanggal_beli = moment(data.tanggal_beli).format("YYYY-MM-DD");
          data.tanggal_pakai = moment(data.tanggal_pakai).format("YYYY-MM-DD");
          this.listCicilan = cicilan;
          data.nilai_dp = this.formatMoney(data.nilai_dp);
          this.formDetail = data;
          this.countRasio();
          this.listLokasi = lokasi;
          this.listPembelian = pembelian;
          this.listHistory = history;
          this.onEdit = true;
          this.bookValue = 0;
          this.assetValue = 0;
          this.OptionWerhouseOrigin = lokasiGudang;
          this.convertCicilan();
          if (nilai.length > 0) {
            this.bookValue = this.formatMoney(nilai[0].nilai_aset);
            this.assetValue = this.formatMoney(
              nilai[nilai.length - 1].nilai_aset
            );
          }
          this.optionPembayaran = [];
          for (const i in cicilan) {
            if (Object.hasOwnProperty.call(cicilan, i)) {
              const el = cicilan[i];
              if (el.tipe == "pembayaran") {
                this.optionPembayaran.push({
                  id: el.no_transaksi,
                  text: el.no_transaksi,
                  id_akun: el.id_akun,
                  nilai: el.nilai_kembali
                    ? el.nilai - el.nilai_kembali
                    : el.nilai,
                });
              }
            }
          }

          for (const key in files) {
            if (Object.hasOwnProperty.call(files, key)) {
              const element = files[key];
              var type = checkFileOrImageFromLink(element.nama_file);
              this.listFile.push({
                id: element.session,
                preview: type == 'file' ? '/img/icon_documents.png' : DATA.ASET(element.nama_file),
                link_preview: DATA.ASET(element.nama_file),
                file: null,
                type: checkFileOrImageFromLink(element.nama_file)
              });
            }
          }

          this.countSisaPembayaran();
        },
        (err) => {
          console.log(err);
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Data aset tidak ditemukan",
            showCancelButton: true,
            showConfirmButton: false,
            onCancel: () => {
              this.$router.push({
                name: "DataAsset",
              });
            },
          });
        }
      );
    },
    getQrcode(string) {
      return DATA.ASET(string);
    },
    getKaryawan() {
      var id_divisi =
        this.accessDepartement && this.formDetail.id_divisi
          ? this.formDetail.id_divisi
          : "";
      get_KaryawanList(
        {
          id_company: this.id_company,
          search: "aktif",
          id_divisi: id_divisi,
        },
        (res) => {
          var { list } = res;
          this.OptionResponsiblePerson = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.OptionResponsiblePerson.push({
                id: element.id,
                text: element.nama,
              });
            }
          }
        }
      );
    },

    getSatuan() {
      get_SatuanList((res) => {
        var { list } = res;
        this.OptionUnit = [
          {
            id: "new",
            text: "Satuan baru",
          },
        ];
        for (const key in list) {
          if (Object.hasOwnProperty.call(list, key)) {
            const element = list[key];
            this.OptionUnit.push({
              id: element.id,
              text: element.nama,
            });
          }
        }
      });
    },

    getGudang() {
      get_GudangList(
        {
          id_company: this.id_company,
          search: "aktif",
        },
        (res) => {
          var { list } = res;
          this.OptionWerhouseDestination = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.OptionWerhouseDestination.push({
                id: element.id,
                text: element.nama,
                alamat: element.alamat,
              });
            }
          }
        }
      );
    },

    getDivisi() {
      get_ListDivisi({ id_company: this.id_company }, (res) => {
        var { list } = res;
        this.OptionDepartement = list;
      });
    },

    getCategoryAsset() {
      get_KategoriAsetList(
        {
          id_company: this.id_company,
          search: "aktif",
        },
        (res) => {
          var { list } = res;
          this.OptionAssetCategory = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.OptionAssetCategory.push({
                id: element.kode,
                text: element.nama,
              });
            }
          }
        }
      );
    },

    getAkun(tipe, not = false) {
      var id_divisi =
        this.accessDepartement && this.formDetail.id_divisi
          ? this.formDetail.id_divisi
          : "";
      get_AkunList(
        {
          id_company: this.id_company,
          not_type: not,
          tipe: tipe,
          id_divisi: id_divisi,
        },
        (res) => {
          var data = [];
          const { list } = res;
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              data.push({
                id: element.id,
                text: `${element.nama}`,
                no: element.id,
                type: element.tipe,
                sub_level: parseInt(element.sub_level),
                disabled:
                  parseInt(element.max_subLevel) === parseInt(element.sub_level)
                    ? false
                    : true,
              });
            }
          }
          if (tipe == "4,5,7,20") {
            this.OptionAssetAccount = data;
          } else if (tipe === "6") {
            this.OptionAccumulatedDepreciationAccount = data;
          } else if (tipe == "14,15,13" && !not) {
            this.OptionDepreciationExpenseAccount = data;
          } else if (tipe == "1,11") {
            this.OptionAccountEstimated = data;
          } else if (tipe == "8,9,10") {
            this.OptionAccoountDebt = data;
          } else if (tipe == "1") {
            this.OptionBank = data;
          }
        }
      );
    },

    getShrinkMethod() {
      get_MetodePenyustanList((res) => {
        this.OptionShrinkageMethod = res.list;
      });
    },

    //master end

    tooltip() {
      $('[data-toggle="tooltip"]').tooltip();
    },

    detailHistory(
      tanggal,
      penanggung_jawab,
      qty,
      satuan,
      gudang_awal,
      gudang_transfer,
      keterangan
    ) {
      this.historyDetail.tanggal = this.convertDate(tanggal);
      this.historyDetail.penanggung_jawab = penanggung_jawab;
      this.historyDetail.kuantitas = `${qty} ${satuan}`;
      this.historyDetail.gudang_asal = gudang_awal;
      this.historyDetail.gudang_tujuan = gudang_transfer
        ? gudang_transfer
        : "-";
      this.historyDetail.keterangan = keterangan;
      $("#detailHistory").modal("show");
      $('[data-toggle="tooltip"]').tooltip("hide");
    },
    changeDate(data, key, tipe = "") {
      if (tipe == "") {
        this.formDetail[key] = moment(data).format("YYYY-MM-DD");
      } else if (tipe === "lokasi") {
        this.formLokasi[key] = moment(data).format("YYYY-MM-DD");
      }
      this.formChange(key, data, tipe);
    },
    async formChange(key, val = "", tipe = "") {
      if (tipe == "") {
        if (this.rules[key]) {
          this.rules[key].changed = true;
        }

        if (key == "tenor" || key == "nilai_aset" || key == "nilai_dp") {
          this.convertCicilan();
        }

        if (key === "payment_type") {
          if (val.id == "cash") {
            delete this.rules.beban_hutang;
            delete this.rules.nilai_aset;
            delete this.rules.tenor;
            delete this.rules.beban_dp;
          } else {
            this.rules = {
              ...this.rules,
              beban_hutang: {
                required: true,
              },
              nilai_aset: {
                required: true,
                min: 1,
              },
              tenor: {
                required: true,
              },
            };
          }
        }
        if (
          key === "nilai_dp" &&
          this.formDetail.nilai_dp != "" &&
          this.formDetail.nilai_dp != 0 &&
          !this.formDetail.nilai_dp
        ) {
          this.rules = {
            ...this.rules,
            akun_bank: {
              required: true,
            },
          };
        } else if (key === "nilai_dp") {
          // delete this.rules.beban_dp
          delete this.rules.akun_bank;
          delete this.rules.tanggal_bayar;
        }

        if (key == "id_divisi") {
          this.formDetail.akun_aset = "";
          this.formDetail.akun_akumulasi_penyusutan = "";
          this.formDetail.akun_beban_penyusutan = "";
          this.listPembelian = [];
          this.getKaryawan();
          this.getAkun("4,5,7,20");
          this.getAkun("6");
          this.getAkun("14,15");
          this.getAkun("1,11", false);
        }

        if (key == "id_satuan" && val.id != "new") {
          this.formDetail.nama_satuan = val.text;
        } else if (key == "id_satuan") {
          this.formDetail.nama_satuan = "";
        }
        if (key === "est_penggunaan_tahun" || key == "est_penggunaan_bulan") {
          this.countRasio();
        }
        var check = await checkRules(this.rules, this.formDetail);
        this.formError = check.error;
      } else if (tipe === "lokasi") {
        if (this.rulesLokasi[key]) {
          this.rulesLokasi[key].changed = true;
        }
        if (key == "id_satuan") {
          this.formLokasi.text_satuan = val.text;
        }
        if (key === "penanggung_jawab") {
          this.formLokasi.nama_penanggung_jawab = val.text;
        }
        if (key == "gudang_awal") {
          this.formLokasi.jumlah = val.jumlah;
          this.formLokasi.id_satuan = val.id_satuan;
          this.formLokasi.gudang_transfer = "";
          this.rulesLokasi.jumlah.max = parseInt(val.jumlah);
        }
        if (key === "gudang_transfer") {
          this.formLokasi.alamat = val.alamat;
        }
        var checka = await checkRules(this.rulesLokasi, this.formLokasi);
        this.formErrorLokasi = checka.error;
      }
    },
    countRasio() {
      var tahun = parseInt(this.formDetail.est_penggunaan_tahun)
        ? parseInt(this.formDetail.est_penggunaan_tahun) * 12
        : 0;
      var bulan = parseInt(this.formDetail.est_penggunaan_bulan)
        ? parseInt(this.formDetail.est_penggunaan_bulan)
        : 0;
      var add = tahun + bulan;
      if (add > 0) {
        var rasioo = 100 / (add / 12);
        if (this.isFloat(rasioo)) {
          this.rasio = rasioo.toFixed(2);
        } else {
          this.rasio = rasioo;
        }
      } else {
        this.rasio = "";
      }
    },
    isFloat(n) {
      return Number(n) === n && n % 1 !== 0;
    },
    formatState(state) {
      if (!state.id) {
        return state.text;
      }
      var padding =
        state.sub_level == 2 ? "pl-4" : state.sub_level == 1 ? "pl-3" : "";
      var $state = $(
        '<div class="' +
          padding +
          '">' +
          state.text +
          '<div class="noEstimated" style="font-size: 12px;color: #898989; display:flex; justify-content: space-between;">' +
          state.no +
          "<div>" +
          state.type +
          "</div></div></div>"
      );
      return $state;
    },
    gudangResult(val) {
      // if (val.id != this.formLokasi.gudang_awal) {
        return val.text;
      // } else {
        // return null;
      // }
    },
    convertDate(string) {
      return moment(string).format("DD MMM YYYY");
    },
    formatMoney(a) {
      if (!a) {
        return 0;
      }
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    myChangeEvent(val) {
      console.log(val);
    },
    mySelectEvent(val, key = "") {
      if (key === "gudang_transfer") {
        this.formLokasi.alamat = val.alamat;
        this.formLokasi.nama_gudang = val.text;
      }
    },
    //Pembalian
    mySelectFormState(event) {
      this.formPembelian.id_akun = event.id;
      this.formPembelian.nama_akun = event.text;
      this.formPembelian.keterangan = "";
      this.formPembelian.jumlah = 0;
      this.formPembelianError = false;
      setTimeout(() => {
        this.AccountEstimated = "";
      }, 500);
      $("#modalAccountEstimated").modal("show");
    },
    updatePembelian() {
      this.formPembelianError = false;
      if (!parseInt(this.formPembelian.jumlah)) {
        this.formPembelianError = true;
      }
    },
    savePembelian() {
      if (parseInt(this.formPembelian.jumlah)) {
        var data = { ...this.formPembelian };
        this.listPembelian.push(data);
        $("#modalAccountEstimated").modal("hide");
      } else {
        this.formPembelianError = true;
      }
    },
    deletePembelian(index) {
      this.listPembelian.splice(index, 1);
    },

    //LOKASI
    showAddLocation() {
      this.formLokasi.penanggung_jawab = "";
      this.formLokasi.gudang_transfer = "";
      this.formLokasi.jumlah = "1";
      this.formLokasi.keterangan = "";
      this.formLokasi.id_satuan = "";
      var jumlah = parseInt(this.formDetail.qty);
      for (const key in this.listLokasi) {
        if (Object.hasOwnProperty.call(this.listLokasi, key)) {
          const element = this.listLokasi[key];
          jumlah -= element.jumlah ? parseInt(element.jumlah) : 0;
        }
      }
      this.rulesLokasi.jumlah.max = jumlah;
      $("#modalAddLocation").modal("show");
    },
    async saveLokasi() {
      for (const key in this.rulesLokasi) {
        if (Object.hasOwnProperty.call(this.rulesLokasi, key)) {
          this.rulesLokasi[key].changed = true;
        }
      }
      var check = await checkRules(this.rulesLokasi, this.formLokasi);
      this.formErrorLokasi = check.error;
      if (check.success) {
        var data = { ...this.formLokasi };
        this.listLokasi.push(data);
        $("#modalAddLocation").modal("hide");
      }
    },
    deleteLokasi(index) {
      this.listLokasi.splice(index, 1);
    },

    countAgeOfAsset(date, created_at) {
      var a = moment(created_at);
      var b = moment(date);

      var string = "";

      var years = a.diff(b, "year");
      b.add(years, "years");
      if (years) {
        string += `${years} Tahun `;
      }
      var months = a.diff(b, "months");
      b.add(months, "months");

      if (months) {
        string += `${months} bulan `;
      }

      var days = a.diff(b, "days");

      if (days) {
        string += `${days} hari `;
      }
      return string;
    },
    //TRANSFER
    showTransfer() {
      this.formErrorLokasi = [];
      this.formLokasi.penanggung_jawab = "";
      this.formLokasi.nama_penanggung_jawab = "";
      this.formLokasi.keterangan = "";
      this.formLokasi.gudang_awal = "";
      this.formLokasi.gudang_transfer = "";
      this.formLokasi.jumlah = "";
      this.formLokasi.tanggal = "";
      this.rulesLokasi = {
        penanggung_jawab: {
          required: true,
        },
        gudang_awal: {
          required: true,
        },
        gudang_transfer: {
          required: true,
        },
        tanggal: {
          required: true,
        },
        alamat: {
          required: false,
        },
        jumlah: {
          required: true,
        },
        id_satuan: {
          required: true,
        },
        text_satuan: {
          required: false,
        },
      };
      $("#modalAddTransfer").modal("show");
    },

    postTransfer() {
      this.isTransfer = true;
      var data = this.formLokasi;
      data.kode = this.kodeAset;
      data.id_divisi = this.formDetail.id_divisi;
      post_AssetLokasiSave(
        data,
        (res) => {
          this.isTransfer = false;
          var msg = "Aset berhasil ditransfer";
          var { lokasi, history, lokasiGudang } = res;
          this.listLokasi = lokasi;
          this.listHistory = history;
          this.OptionWerhouseOrigin = lokasiGudang;
          $("#modalAddTransfer").modal("hide");
          setTimeout(() => {
            showAlert(this.$swal, {
              title: "BERHASIL!",
              msg: msg,
            });
          }, 500);
        },
        () => {
          this.isTransfer = false;
          setTimeout(() => {
            showAlert(this.$swal, {
              title: "GAGAL!",
              msg: "Terjadi kesalahan, silakan ulangi kembali",
              showCancelButton: true,
              showConfirmButton: false,
            });
          }, 500);
        }
      );
    },

    formatDate(string) {
      return moment(string).format("DD MMMM YYYY");
    },

    async setTransfer() {
      for (const key in this.rulesLokasi) {
        if (Object.hasOwnProperty.call(this.rulesLokasi, key)) {
          this.rulesLokasi[key].changed = true;
        }
      }
      console.log(this.listLokasi);
      var checkIndex = this.listLokasi.findIndex((v) => v.penanggung_jawab == this.formLokasi.penanggung_jawab && v.gudang_transfer == this.formLokasi.gudang_transfer);
      var check = await checkRules(this.rulesLokasi, this.formLokasi);
      this.formErrorLokasi = check.error;
      if (check.success && checkIndex < 0) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postTransfer();
          },
        });
      } else {
        console.log(checkIndex)
        if (checkIndex >= 0) {
          showAlert(this.$swal, {
          title: "ERROR!",
          msg: "Penanggung jawab dan lokasi transfer tidak boleh sama dengan lokasi saat ini, silakan ubah salah satu data.",
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: "Tutup",
        });
        }
      }
    },
    countLokasiBarang() {
      var nilai = 0;
      for (const key in this.listLokasi) {
        if (Object.hasOwnProperty.call(this.listLokasi, key)) {
          const element = this.listLokasi[key];
          nilai += parseInt(element.jumlah);
        }
      }
      return nilai;
    },
    //SAVE
    postData() {
      var data = {...this.formDetail};
      data.akun_pembelian = this.listPembelian;
      data.lokasi = this.listLokasi;

      var dataForm = new FormData();
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          const element = data[key];
          if (key == "akun_pembelian" || key == "lokasi") {
            element.forEach((obj, index) => {
              Object.entries(obj).forEach(([i, value]) => {
                dataForm.append(`${key}[${index}][${i}]`, value);
              });
            });
          } else{
            dataForm.append(key, element);
          }
        }
      }
      // this.formData.files = [];
      for (const key in this.listFile) {
        if (Object.hasOwnProperty.call(this.listFile, key)) {
          const element = this.listFile[key];
          if (element.file) {
            dataForm.append(`files[]`, element.file);
          }
        }
      }
      for (const key in this.fileDelete) {
        if (Object.hasOwnProperty.call(this.fileDelete, key)) {
          const element = this.fileDelete[key];
          dataForm.append(`deletefile[]`, element);
        }
      }

      this.isSubmit = true;
      post_AssetSave(
        dataForm,
        (res) => {
          this.isSubmit = false;
          var msg =
            res.response_code == 201
              ? "Data aset berhasil dicatat"
              : "Data aset berhasil diperbarui";
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: msg,
            onSubmit: () => {
              this.$router.push({
                name: "DataAsset",
              });
            },
          });
        },
        () => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi kesalahan, silakan ulangi kembali",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },
    async onSubmit() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }
      var check = await checkRules(this.rules, this.formDetail);
      this.formError = check.error;
      var check_pembelian = this.listPembelian.length > 0 ? true : false;
      var check_lokasi = this.listLokasi.length > 0 ? true : false;
      var jumlahLokasi = await this.countLokasiBarang();
      if (this.formDetail.payment_type == "cicilan" && check.success) {
        check_pembelian = true;
      }
      if (
        check.success &&
        check_pembelian &&
        check_lokasi &&
        jumlahLokasi === parseInt(this.formDetail.qty)
      ) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: this.formDetail.kode
            ? "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?"
            : "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      } else {
        if (check.success && !check_pembelian) {
          showAlert(this.$swal, {
            title: "GAGAL",
            showConfirmButton: false,
            msg: "Sebelum menyimpan data, isi terlebih dahulu Akun pembelian",
          });
          $("#pills-profile-tab").tab("show");
        } else if (check.success && !check_lokasi) {
          showAlert(this.$swal, {
            title: "GAGAL",
            showConfirmButton: false,
            msg: "Sebelum menyimpan data, isi terlebih dahulu Lokasi Aset",
          });
          $("#pills-etc-tab").tab("show");
        } else if (check.success && jumlahLokasi != this.formDetail.qty) {
          showAlert(this.$swal, {
            title: "GAGAL",
            showConfirmButton: false,
            msg: "Jumlah aset dengan total aset dilokasi tidak sama",
          });
          $("#pills-etc-tab").tab("show");
        }
      }
    },

    //cicilan
    postPembayaran() {
      var dataForm = this.formPembayaran;
      dataForm.nilai = this.formPembayaran.nilai.split(".").join("");
      this.isPembayaran = true;
      post_AssetSavePembayaran(
        dataForm,
        (res) => {
          this.isPembayaran = false;
          var msg =
            res.response_code === 201
              ? "Data pembayaran berhasil dicatat"
              : "Data pembayaran berhasil diperbarui";
          this.getData();

          $("#modalAddPayment").modal("hide");
          setTimeout(() => {
            this.isPembayaran = false;
            showAlert(this.$swal, {
              title: "BERHASIL!",
              msg: msg,
            });
          }, 500);
        },
        () => {
          this.isPembayaran = false;
          setTimeout(() => {
            this.isPembayaran = false;
            showAlert(this.$swal, {
              title: "ERROR!",
              msg: "Terjadi kesalahan, silakan ulangi kembali",
              showCancelButton: true,
              showConfirmButton: false,
            });
          }, 500);
        }
      );
    },

    async savePembayaran() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }
      this.formPembayaran.nilai = this.formatMoney(this.formPembayaran.nilai);
      var check = await checkRules(this.rulesPembayaran, this.formPembayaran);
      this.formError = check.error;
      if (check.success) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postPembayaran();
          },
        });
      }
    },

    createPembayaran() {
      this.countSisaPembayaran();
      this.formPembayaran.no_transaksi = "";
      this.formPembayaran.tanggal_pembayaran = "";
      this.formPembayaran.no_referensi = "";
      this.formPembayaran.status = "";
      this.formPembayaran.nilai = "";
      this.formPembayaran.id_akun = "";
      this.formErrorPembayaran = [];
      $("#modalAddPayment").modal("show");
    },

    countSisaPembayaran() {
      var sisa = this.formDetail.nilai_aset
        ? parseInt(this.formDetail.nilai_aset.toString().split(".").join(""))
        : 0;
      for (const key in this.listCicilan) {
        if (Object.hasOwnProperty.call(this.listCicilan, key)) {
          const element = this.listCicilan[key];
          sisa -= element.nilai;
        }
      }
      this.sisaPembayaran = sisa;
      this.rulesPembayaran.nilai.max = this.formatMoney(sisa);
    },

    changeDatePembayaran(data, key) {
      this.formPembayaran[key] = moment(data).format("YYYY-MM-DD");
      this.formChangePembayaran(key);
    },

    async formChangePembayaran(val) {
      if (Object.hasOwnProperty.call(this.rulesPembayaran, val)) {
        this.rulesPembayaran[val].changed = true;
      }
      var check = await checkRules(this.rulesPembayaran, this.formPembayaran);
      this.formErrorPembayaran = check.error;
    },

    //jurnal kembali
    async formChangeJurnal(val, value = "") {
      if (Object.hasOwnProperty.call(this.rulesJurnalKembali, val)) {
        this.rulesJurnalKembali[val].changed = true;
      }

      if (val == "id_pembayaran") {
        this.rulesJurnalKembali.nilai.max = this.formatMoney(value.nilai);
        this.formJurnalKembali.akun_kas = value.id_akun;
      }

      var check = await checkRules(
        this.rulesJurnalKembali,
        this.formJurnalKembali
      );
      this.formErrorJurnal = check.error;
    },
    changeDateJurnal(data, key) {
      this.formJurnalKembali[key] = moment(data).format("YYYY-MM-DD");
      this.formChangeJurnal(key);
    },
    flipJournal() {
      this.formJurnalKembali.id_pembayaran = "";
      this.formJurnalKembali.tanggal = "";
      this.formJurnalKembali.nilai = "";
      this.formJurnalKembali.keterangan = "";
      this.formJurnalKembali.no_referensi = "";
      this.formErrorJurnal = [];
      $("#modalJurnalBack").modal("show");
      // this.$router.push({
      //   name: "FlipJournalPayble",
      //   params: {
      //     label: "Hutang",
      //   },
      // });
    },
    postJurnalBack() {
      this.isJurnalback = true;
      post_AssetJurnalBack(
        this.formJurnalKembali,
        () => {
          $("#modalJurnalBack").modal("hide");
          this.isJurnalback = false;
          this.getData();
          setTimeout(() => {
            showAlert(this.$swal, {
              title: "BERHASIL!",
              msg: "Jurnal kembali berhasil dicatat",
            });
          }, 500);
        },
        () => {
          this.isJurnalback = false;
          showAlert(this.$swal, {
            title: "ERROR!",
            msg: "Terjadi kesalahan, silakan ulangi kembali",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },
    async saveJurnalBack() {
      for (const key in this.rulesJurnalKembali) {
        if (Object.hasOwnProperty.call(this.rulesJurnalKembali, key)) {
          this.rulesJurnalKembali[key].changed = true;
        }
      }
      var check = await checkRules(
        this.rulesJurnalKembali,
        this.formJurnalKembali
      );
      this.formErrorJurnal = check.error;
      if (check.success) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postJurnalBack();
          },
        });
      }
    },

    convertCicilan() {
      var _nilai = this.formDetail.nilai_aset
        ? parseInt(this.formDetail.nilai_aset.toString().split(".").join(""))
        : 0;
      var _tenor = this.formDetail.tenor
        ? parseInt(this.formDetail.tenor)
        : this.formDetail.tenor;
      var _nilaidp = this.formDetail.nilai_dp
        ? parseInt(this.formDetail.nilai_dp.toString().split(".").join(""))
        : 0;
      if (!_nilai || !_tenor) {
        this.cicilanPer = 0;
      } else {
        this.cicilanPer = this.formatMoney(
          Math.ceil((_nilai - _nilaidp) / _tenor)
        );
      }
    },
  },
};
</script>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>

<style scoped>
.btn-save {
  background-color: #40ddd4;
  color: #fff;
  /* width: 90px; */
  font-size: 16px;
  font-weight: 400;
  padding: 10px 0px;
}

.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.card .nav .nav-item {
  margin-right: 30px;
}

.card {
  padding: 20px 50px;
  position: relative;
}

.card {
  border: 1px solid #eaeaea;
  border-radius: 10px;
}

.cr {
  color: #ff3d3d;
}

.img-barcode {
  position: absolute;
  right: 34px;
  max-width: 100px;
}
.text {
  font-size: 13px;
}

.form-label {
  width: 324px;
}
</style>
